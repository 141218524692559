/* globals $ */

(function () {
  'use strict'
  $(document).ready(function () {
    $('.plus').on('click', function (e) {
      e.preventDefault()

      if($(this).hasClass('shown')) {
        $(this).parent().find('.more').removeClass('display')
        $(this).parent().find('.more').slideUp('fast')
        $(this).removeClass('shown')
      } else {
        $('.more').removeClass('display')
        $('.plus').removeClass('shown')
        $(this).parent().find('.more').slideToggle('fast')
        $(this).toggleClass('shown')
        var self = $(this).parent().find('.more')

        setTimeout(function() {
          self.toggleClass('display')
          $('.more').css('display', 'none')
        }, 30)
      }
    })

    $('.hamburger').click(function () {
      $('.nav').addClass('opened')
      $('body').addClass('lock')
    })

    $(".close").click(function () {
      $('.nav').removeClass('opened')
      $('body').removeClass('lock')
    })
  })
})()